import gapiConfig from '../config/gapiConfig';
import { gapi } from 'gapi-script';

// https://github.com/google/google-api-javascript-client/blob/master/docs/reference.md

const initializeGapiClient = async (callback) => {
    await gapi.client.init({
        discoveryDocs: gapiConfig.discoveryDocs,
    });
    callback()
}

const loadGapi = (callback) => {
    gapi.load('client', () => { initializeGapiClient(callback) });
}

export { loadGapi }