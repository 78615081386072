import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import { createFirestoreInstance } from 'redux-firestore'

const fbConfig = {
    apiKey: "AIzaSyBz4q2lem_YGzoeNFgwJKUooKJd-cESnQI",
    authDomain: "frdrk-gcounter.firebaseapp.com",
    projectId: "frdrk-gcounter",
    storageBucket: "frdrk-gcounter.appspot.com",
    messagingSenderId: "431080822722",
    appId: "1:431080822722:web:bb74956af6d517b8ed285b",
    measurementId: "G-RZP586ZSTS"
}
firebase.initializeApp(fbConfig)

const firestore = firebase.firestore()
const functions = firebase.functions()

// firestore.settings({
//     host: "localhost:8080",
//     ssl: false,
//   })
// functions.useEmulator('localhost', 5001)

const getRrfProps = (store) => {
    // react-redux-firebase config
    const rrfConfig = {
        userProfile: 'users',
        useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
        // enableClaims: true // Get custom claims along with the profile
    }
    const rrfProps = {
        firebase,
        config: rrfConfig,
        dispatch: store.dispatch,
        createFirestoreInstance
    }
    return rrfProps
}

export { getRrfProps }
export default fbConfig