import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import gapiConfig from '../config/gapiConfig';

/* global gapi, google */
// https://developers.google.com/identity/oauth2/web/reference/js-reference#google.accounts.oauth2.initTokenClient

// Implicit authorization
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
const loadGis = (callbackFunction) => {
    const firebase = getFirebase()
    const firestore = getFirestore()
    const currentUser = firebase.auth().currentUser

    const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: gapiConfig.authScopes.join(' '),
        prompt: '',
        callback: (tokenResponse) => {
            const access_token = tokenResponse.access_token;
            gapi.client.setToken({ access_token })

            firestore.collection('users').doc(currentUser.uid).update({
                accessToken: access_token
            })

            callbackFunction()
        },
    });
    tokenClient.requestAccessToken({
        hint: currentUser.email
    });
}

const grantOfflineAccess = async () => {
    const functions = getFunctions()
    const client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: gapiConfig.authScopes.join(' '),
        ux_mode: 'popup',
        callback: async (response) => {
            // TODO Check is scope matches requested scope
            const {code, scope} = response

            const retrieveTokens = httpsCallable(functions, 'retrieveTokens');
            retrieveTokens({ code })
        },
    });
    client.requestCode()
}

export { grantOfflineAccess, loadGis }