import { createSlice } from "@reduxjs/toolkit"

const gapiLoadedSlice = createSlice({
  name: 'gapiLoaded',
  initialState: null,
  reducers: {
    init: (state, action) => {
      return action.payload
    }
  },
})

export const { init } = gapiLoadedSlice.actions
export default gapiLoadedSlice.reducer