import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebase } from 'react-redux-firebase'
import { getFirestore } from 'redux-firestore'

/* global gapi */

const AUTH_SCOPES = [
  'https://www.googleapis.com/auth/gmail.readonly',
]


const login = createAsyncThunk(
  'auth/login',
  async (data, thunkAPI) => {
      const firebase = getFirebase()
      const firestore = getFirestore()

      const resp = await firebase.login({
        provider: 'google',
        type: 'popup',
        scopes: AUTH_SCOPES,
      })
  
      firestore.collection('users').doc(resp.user.uid).update({
        accessToken: resp.credential.accessToken
      })
  
      gapi.client.setToken({ access_token: resp.credential.accessToken })

      // Cal to function that user logged in
      const functions = getFunctions()
      const fetchLabels = httpsCallable(functions, 'fetchLabels');
      fetchLabels({ accessToken: resp.credential.accessToken })

      return data
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    logout: (state, action) => {
        const firebase = getFirebase()
  
        localStorage.removeItem("gmailData")

        gapi.client.setToken(null)
        firebase.auth().signOut()
  
        return {
          ...state,
          navigateTo: action.payload.navigateTo
        }
    },
    navigateToExecuted: (state, action) => {
      const newState = {...state}
      delete newState['navigateTo']
      return newState
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(login.fulfilled, (state, action) => {
      const newState = {...state}
      delete newState['msg']
      newState.navigateTo = action.payload.navigateToSuccess
      return newState
    })
    .addCase(login.rejected, (state, action) => {
      console.log(action.payload.message)
      state.msg = `An error occured while logging in: ${action.error.message}`
      state.navigateTo = action.payload.navigateToError
    })
  }
})

export { login }
export const { logout, navigateToExecuted } = authSlice.actions
export default authSlice.reducer