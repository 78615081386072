import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { init } from '../store/reducers/gapiLoadedReducer';
import { loadGapi } from '../util/gapi';

import GmailStats from './GmailStats';
import Navbar from './Navbar'

function App() {
  const firebaseAuth = useSelector(state => state.firebase.auth)
  const dispatch = useDispatch()

  console.log(getFirebase().app())

  useEffect(() => {
    if (firebaseAuth.uid) {
      loadGapi(() => { dispatch(init("OK")) })
    }
  }, [firebaseAuth])

  return (
    <BrowserRouter>
      <main>
        <Navbar />
        <div className='container-xl mt-3'>
          <Routes>
            <Route path="/" element={<div></div>} />
            <Route path="/gmail-stats" element={<GmailStats />} />
          </Routes>
        </div>
      </main>
    </BrowserRouter>
  );
}

export default App;
