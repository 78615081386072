import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout, navigateToExecuted } from '../store/reducers/authReducer'
import { useEffect } from 'react'
import { grantOfflineAccess } from '../util/googleIdentity'

const Navbar = (props) => {
    // https://developers.google.com/gmail/api/quickstart/js

    const firebaseAuth = useSelector(state => state.firebase.auth)
    const firebaseProfile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.auth)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const clickLogin = async () => {
        dispatch(login({
            navigateToSuccess: '/gmail-stats',
            navigateToError: '/',
        }))
    }

    const clickLogout = async () => {
        dispatch(logout({ navigateTo: '/' }))
    }

    useEffect(() => {
        if (auth.navigateTo) {
            dispatch(navigateToExecuted())
            navigate(auth.navigateTo)
        }

    }, [auth])

    const clickGrantOffline = async () => {
        grantOfflineAccess()
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-xl">
                <Link to='/' className="navbar-brand"><i className="bi bi-bar-chart"></i> &nbsp; Gstats</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07XL" aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExample07XL">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {firebaseAuth.uid &&
                            <li className="nav-item">
                                <Link to="/gmail-stats" className="nav-link active" aria-current="page">
                                    Gmail Stats
                                </Link>
                            </li>
                        }
                    </ul>
                    <div className="col-md-auto text-end">
                        {!firebaseAuth.uid &&
                            <button type="button" className="btn btn-outline-primary me-2" onClick={clickLogin}>Login</button>
                        }
                        {firebaseAuth.uid &&
                            <div>
                                <span className="text-light">Logged in as {firebaseAuth.displayName} ({firebaseAuth.email})</span>
                                {!firebaseProfile.offlineAccess && <button type="button" className="btn btn-primary ms-3" onClick={clickGrantOffline}>Grant Offline</button>}
                                <button type="button" className="btn btn-primary ms-3" onClick={clickLogout}>Logout</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar