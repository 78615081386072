import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";

import { Chart } from 'chart.js/auto'
import 'chartjs-adapter-luxon';
import { DateTime } from "luxon";
import GmailLabels from "./GmailLabels";
import { getFunctions, httpsCallable } from "firebase/functions";

function GmailStats() {
    const [chart, setChart] = useState()
    const [scale, setScale] = useState()
    const [selectedLabel, setSelectedLabel] = useState('INBOX')
    const [currentCount, setCurrentCount] = useState({time: null, count: null})
    const [labels, setLabels] = useState({})

    const firebaseAuth = useSelector(state => state.firebase.auth)
    const firebaseProfile = useSelector(state => state.firebase.profile)

    const firestore = useFirestore()
    const functions = getFunctions()

    const keyCurrentMonth = `${firebaseAuth.uid}-${DateTime.utc().toFormat('yyyy-MM')}`
    useFirestoreConnect(() => [
        { collection: 'gmail-stats', doc: keyCurrentMonth }
    ])
    const gmailDataCurrentMonth = useSelector(
        ({ firestore: { data } }) => data['gmail-stats'] && data['gmail-stats'][keyCurrentMonth]
    )

    const scales = [
        { desc: 'One hour', timeAgo: { hours: -1 }, unit: 'minute' },
        { desc: 'One day', timeAgo: { days: -1 }, unit: 'hour' },
        { desc: 'One week', timeAgo: { weeks: -1 }, unit: 'day' },
        { desc: 'One month', timeAgo: { months: -1 }, unit: 'day' },
        { desc: 'One year', timeAgo: { years: -1 }, unit: 'week' },
        { desc: 'Two years', timeAgo: { years: -2 }, unit: 'week' },
    ]
    const defaultScale = 2

    const chartSets = [
        { label: 'Threads Total', hidden: false },
        { label: 'Messages Total', hidden: true },
        { label: 'Threads Unread', hidden: false },
        { label: 'Messages Unread', hidden: true },
    ]

    const changeScale = (e) => {
        e.preventDefault()

        const newScale = scales[e.target.value]

        setScale({
            min: DateTime.now().plus(newScale.timeAgo),
            unit: newScale.unit,
        })
    }

    const changeSelectedLabel = (e) => {
        e.preventDefault()
        setSelectedLabel(e.target.value)
    }

    const clickFetchAgain = (e) => {
        e.preventDefault()
        const fetchLabels = httpsCallable(functions, 'fetchStatsForMe');
        fetchLabels({ label: selectedLabel })
    }

    const storeDataForLater = (updateObject) => {
        localStorage.setItem("gmailData", JSON.stringify(updateObject))
    }

    const getStoredData = () => {
        let storedData = {}
        const localStorageData = localStorage.getItem("gmailData")
        if (localStorageData !== null) {
            storedData = JSON.parse(localStorageData)
            // Transform ISO dates in DateTimes again
            storedData = Object.fromEntries(
                Object.entries(storedData).map(
                    ([k, v]) => [k, { ...v, fetched: DateTime.fromISO(v.fetched, { zone: 'UTC' }) }]
                )
            )
        }
        return storedData
    }

    const drawChart = async ({ fetchCurrentMonth = false } = {}) => {
        if (!scale)
            return

        // Fetch which months the current time frame needs
        let monthsToRetrieve = [scale.min]
        while (monthsToRetrieve.at(-1) < DateTime.now().startOf('month')) {
            monthsToRetrieve.push(monthsToRetrieve.at(-1).plus({ months: 1 }))
        }
        const yearMonthStrToRetrieve = monthsToRetrieve.map(dt => dt.toFormat('yyyy-MM'))

        // Check which data we already have (state, localStorage)
        let updateObject = getStoredData()

        // Fetch the data from the DB (if we're missing historical data)
        await Promise.allSettled(yearMonthStrToRetrieve.map(async (yearMonth) => {
            const currentData = updateObject[yearMonth]
            const fetchFinalAfter = DateTime.fromFormat(`${yearMonth}-01`, "yyyy-MM-dd", { zone: 'UTC' }).endOf('month')

            // Do not fetch if we already have data and that data is up to date or we specifically choose not to fetch again
            if (currentData && (!fetchCurrentMonth || currentData.fetched > fetchFinalAfter)) {
                return
            }
            console.log(`Fetching ${yearMonth}`)
            const dbData = await getDataForMonth(yearMonth)
            updateObject[yearMonth] = dbData
        }))

        console.log(updateObject)

        // Store the data (state, localStorage)
        storeDataForLater(updateObject)

        // Initialize chart
        chart.data.labels = []
        chartSets.forEach((cs, i) => {
            chart.data.datasets[i].data = []
        })
        updateChart()

        // Prepare data for chart
        yearMonthStrToRetrieve.filter(yearMonth => updateObject.hasOwnProperty(yearMonth)).forEach((yearMonth) => {
            const dataForMonth = updateObject[yearMonth].data

            const labelStats = dataForMonth?.stats?.[selectedLabel]
            if (!labelStats || labelStats.length === 0) return

            const days = Object.keys(labelStats).sort()

            days.forEach((day) => {
                const allData = labelStats[day]
                const timeAndCountsList = allData.split(',')
                timeAndCountsList.forEach(timeAndCounts => {
                    const [time, countsString] = timeAndCounts.split(':')
                    const counts = countsString.split('|')

                    const timestamp = `${dataForMonth.year}${dataForMonth.month}${day}${time}`
                    const t = DateTime.fromFormat(timestamp, "yyyyMMddHHmmssSSS", { zone: 'UTC' }).setZone('Europe/Brussels')
                    if (t >= scale.min.plus({ hours: -1 })) {
                        chart.data.labels.push(t)
                        chartSets.forEach((cs, i) => {
                            chart.data.datasets[i].data.push(parseInt(counts[i]))
                        })
                    }
                })
            })
        })

        setCurrentCount({
            time: chart.data.labels.at(-1),
            count: chart.data.datasets[0].data.at(-1),
        })

        updateChart()
    }

    // const extraData = (e) => {
    //     e.preventDefault()

    //     chart.data.labels.push(DateTime.local())
    //     chart.data.datasets[0].data.push(500)
    //     chart.data.datasets[1].data.push(500)
    //     chart.update()
    // }

    const getDataForMonth = async (yearMonth) => {
        let gmailStats = {
            fetched: DateTime.utc(),
            data: {},
        }
        const key = `${firebaseAuth.uid}-${yearMonth}`

        try {
            const doc = await firestore.collection('gmail-stats').doc(key).get()
            if (doc.exists) {
                gmailStats.data = doc.data()
            }
        } catch (e) {
            console.log(e)
        }

        return gmailStats
    }

    const updateChart = (data) => {
        if (chart) {
            if (data) {
                chart.data = data
            }
            chart.options.radius = (scale.min < DateTime.now().plus({ months: -1 })) ? 0 : 2
            chart.options.scales.x.min = scale.min.toISO()
            chart.options.scales.x.time.unit = scale.unit
            chart.update()
        }
    }

    useEffect(() => {
        if (!firebaseProfile.gmailLabels)
            return

        console.log(firebaseProfile.gmailLabels)
        let dbLbels = Object.entries(firebaseProfile.gmailLabels).map(([id, labelData]) => { return {id, name: labelData.name} })
        dbLbels.sort((l1, l2) => {
            return l1.name.localeCompare(l2.name)
        })
        console.log(dbLbels)
        setLabels(dbLbels)
    }, [firebaseProfile.gmailLabels])

    useEffect(() => {
        drawChart({ fetchCurrentMonth: true })
    }, [scale, selectedLabel])

    useEffect(() => {
        if (gmailDataCurrentMonth) {
            let storedData = getStoredData()
            storedData[`${gmailDataCurrentMonth.year}-${gmailDataCurrentMonth.month}`] = {
                fetched: DateTime.utc(),
                data: gmailDataCurrentMonth,
            }
            storeDataForLater(storedData)
            drawChart()
        }
    }, [gmailDataCurrentMonth])

    useEffect(() => {
        let datasets = chartSets.map(cs => { return { label: cs.label , data: [] , hidden: cs.hidden} })
        const chart = new Chart(
            document.getElementById('stats'),
            {
                type: 'line',
                data : {
                    labels: [],
                    datasets: datasets,
                },
                options: {
                    animation: false,
                    maintainAspectRatio: false,
                    radius: 2,
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'hours',
                                displayFormats: {
                                    day: 'd/MM'
                                },
                            },
                            ticks: {
                                source: 'auto'
                            },
                            min: DateTime.now().plus({ hours: -1 }).toISO(),
                        },
                        // y: {
                        //     min: 0,
                        // }
                    }
                }
            }
        );
        setChart(chart)

        setScale({
            min: DateTime.now().plus(scales[defaultScale].timeAgo),
            unit: scales[defaultScale].unit,
        })

        return () => {
            chart.destroy()
        }
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-12 mb-4">
                    <div className="form-floating">
                        <select className="form-select" id="changeScale" defaultValue={defaultScale} onChange={changeScale} >
                            {scales.map((scale, index) => {
                                return (
                                    <option key={index} value={index}>{scale.desc}</option>
                                )
                            })}
                        </select>
                        <label htmlFor="changeScale">Time frame</label>
                    </div>
                    <div className="form-floating mt-2">
                        <select className="form-select" id="changeSelectedLabel" defaultValue="INBOX" value={selectedLabel} onChange={changeSelectedLabel} >
                            <option value="ALL">General</option>
                            {Object.values(labels).map(label => {
                                return (
                                    <option key={label.id} value={label.id}>{label.name}</option>
                                )  
                            })}
                        </select>
                        <label htmlFor="changeScale">Label</label>
                    </div>
                    <button type="button" className="btn btn-outline-secondary w-100 mt-2" onClick={clickFetchAgain}>Fetch Again</button>
                    {currentCount && currentCount.time && <div className="text-center m-0 mt-2">
                        <p className="fs-6 m-0">Last count:</p>
                        <p className="fs-1 m-0">{currentCount.count}</p>
                        <p className="fs-6 m-0" style={{fontSize: '0.6rem'}}>{currentCount.time.toFormat('dd/MM/yyyy HH:mm:ss')}</p>
                    </div>}
                </div>
                <div className="col-lg-9 col-12">
                    {/* <a href="/#" onClick={fetchData}>Refresh</a> |&nbsp; */}
                    {/* <a href="/#" onClick={extraData}>Extra Data</a> |&nbsp; */}
                    <div style={{ height: '300px' }}>
                        <canvas id="stats"></canvas>
                    </div>
                </div>
            </div>

            <GmailLabels />
        </div>
    );
}

export default GmailStats;
