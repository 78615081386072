import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"

function GmailLabels() {
    const [labelsSorted, setLabelsSorted] = useState([])
    const firebaseAuth = useSelector(state => state.firebase.auth)

    useFirestoreConnect(() => [
        { collection: 'users', doc: firebaseAuth.uid }
    ])
    const user = useSelector(
        ({ firestore: { data } }) => data.users && data.users[firebaseAuth.uid]
    )

    const sortLabels = ({e = undefined, sortOn = 'threadsTotal'} = {}) => {
        if (e) e.preventDefault()

        console.log(`Sort On: ${sortOn}`)
        
        let labels = [...labelsSorted]
        if (labels.length === 0) {
            labels = user.gmailLabels
            labels = Object.entries(labels).map(([k, v]) => { return {id: k, ...v} }).filter(label => label.type !== 'system')
        }

        labels.sort((l1, l2) => {
            if (typeof(l2[sortOn]) === 'number') {
                return l2[sortOn]-l1[sortOn]
            }
            else {
                return l1[sortOn].localeCompare(l2[sortOn])
            }
        })

        setLabelsSorted(labels)
    }

    useEffect(() => {
        if (!user || !user.gmailLabels) return
        sortLabels()
    }, [user])

    return (
        <div className="container">
            <div className="w-100 text-end my-3">
                sort on: <a href="/#" onClick={(e) => sortLabels({e: e, sortOn: 'threadsTotal'})}>threads total</a> | <a href="/#" onClick={(e) => sortLabels({e: e, sortOn: 'name'})}>name</a>
            </div>
            <div className="row row-cols-auto">
                {labelsSorted && labelsSorted.map(label => {
                    let style = 'btn btn-outline-secondary btn-sm'
                    if (['INBOX', 'ALL'].includes(label.id)) {
                        style = 'btn btn-secondary btn-sm'
                    }
                    return (
                        <div className="col my-2" key={label.id}>
                            <button type="button" className={style}>{label.name} ({label.threadsTotal})</button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GmailLabels